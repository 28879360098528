import * as React from 'react';
import PropTypes from 'prop-types';

// bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IoMdClose } from 'react-icons/io';

const ModalExplanation = ({ Whatisit, setWhatisit, t }) => {
  const ps = t('modal_desc').split('\n\n');

  return (
    <Modal show={Whatisit} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title>{t('modal_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '60vh', overflow: 'auto' }}>
        {ps.map((p, index) => (
          <p key={index}>{p}</p>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={() => {
            setWhatisit(false);
          }}>
          {t('toast_closemodal')} <IoMdClose />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalExplanation.propTypes = {
  Whatisit: PropTypes.bool,
  t: PropTypes.string,
  setWhatisit: PropTypes.func
};

export default ModalExplanation;
