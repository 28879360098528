import * as React from 'react';
import CopyCommands from './views/CopyCommands';
import resources from './assets/i18n.json';

import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';

const params = new URLSearchParams(document.location.search);
const language = params.get('language');

i18n.use(initReactI18next).init({
  resources: resources,
  lng: language,
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false
  }
});

const App = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <CopyCommands t={t} language={language} />
    </React.Fragment>
  );
};

export default App;
