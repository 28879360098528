import * as React from 'react';
import PropTypes from 'prop-types';

// bootstrap
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { IoMdClose } from 'react-icons/io';

const ModalPredefinedCommands = ({ PredefinedModal, setCommands, setPredefinedModal, t }) => {
  const [selectedArray, setselectedArray] = React.useState([]);
  const [predefined] = React.useState([
    {
      id: `git-${Math.random()}`,
      title: 'GIT',
      list: [
        { command: 'git status' },
        { command: 'git add .' },
        { command: 'git commit -m "comment"' },
        { command: 'git push origin main' },
        { command: 'git pull origin main' }
      ]
    },
    {
      id: `npm-${Math.random()}`,
      title: 'NPM',
      list: [{ command: 'npm install' }, { command: 'npm run start' }, { command: 'npm run build' }]
    },
    {
      id: `docker-${Math.random()}`,
      title: 'DOCKER',
      list: [
        { command: 'docker-compose up' },
        { command: 'docker-compose build' },
        { command: 'docker-compose down' },
        { command: 'docker-compose stop' }
      ]
    }
  ]);

  return (
    <Modal
      show={PredefinedModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <Modal.Title>{t('toast_addsome')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '60vh', overflow: 'auto' }}>
        <p>{t('toast_select')}</p>
        {predefined && (
          <React.Fragment>
            {predefined.map((element, index) => {
              return (
                <React.Fragment key={index}>
                  <Form.Check
                    onChange={(e) => {
                      const newArray = selectedArray.filter((el) => el.title !== element.title);
                      if (
                        e.target.checked &&
                        !selectedArray.find((el) => el.title === element.title)
                      ) {
                        setselectedArray([...newArray, element]);
                      } else {
                        setselectedArray(newArray);
                      }
                    }}
                    type="checkbox"
                    label={
                      <div className="d-flex align-items-baseline">
                        <h6>{element.title}</h6>
                        <p className="mx-2">
                          {element.list.map((el, index2) => (
                            <small key={index2} className="mx-2">
                              {el.command}
                            </small>
                          ))}
                        </p>
                      </div>
                    }
                    value={element.title}
                    name={`check-${index}`}
                    id={`check-${index}`}
                  />
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!(!!selectedArray.length > 0)}
          onClick={() => {
            setCommands(selectedArray);
            setselectedArray([]);
            setPredefinedModal(false);
          }}>
          {t('toast_includecommands')}
        </Button>
        <Button
          variant="outline-danger"
          onClick={() => {
            setPredefinedModal(false);
          }}>
          {t('toast_closemodal')} <IoMdClose />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ModalPredefinedCommands.propTypes = {
  PredefinedModal: PropTypes.bool,
  setCommands: PropTypes.func,
  setPredefinedModal: PropTypes.func,
  t: PropTypes.string
};
export default ModalPredefinedCommands;
